import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/1.jpg";
import projImg2 from "../assets/img/2.jpg";
import projImg3 from "../assets/img/3.jpg";
import projImg4 from "../assets/img/4.jpg";
import projImg5 from "../assets/img/5.jpg";
import projImg6 from "../assets/img/6.jpg";
import projImg7 from "../assets/img/7.jpg";
import projImg8 from "../assets/img/8.jpg";
import projImg9 from "../assets/img/9.jpg";
import projImg10 from "../assets/img/10.jpg";
import projImg11 from "../assets/img/11.jpg";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const projects = [
    {
      title: "สร้างประวัติส่วนตัว",
      description: "ทำด้วยเว็บไซต์ Thunkable",
      imgUrl: projImg1,
    },
    {
      title: "Project 1",
      description: "สร้างกล่องรับชื่อ เเล้วแสดงชื่อ",
      imgUrl: projImg2,
    },
    {
      title: "Project 2",
      description: "สร้างประวัติส่วนตัวด้วย react",
      imgUrl: projImg3,
    },
    {
      title: "Project 3",
      description: "โปรแกรมบวกเลข",
      imgUrl: projImg4,
    },
    {
      title: "Project 4",
      description: "สร้างฟอร์มสมัครสมาชิก",
      imgUrl: projImg5,
    },
    {
      title: "Project 5",
      description: "สร้างข้อสอบ 20 ข้อ พร้อมบอกคะเเนนเมื่อทำข้อสอบเสร็จ",
      imgUrl: projImg6,
    },
    {
      title: "Project 6",
      description: "สร้างข้อมูลเปิดปิด",
      imgUrl: projImg7,
    },
    {
      title: "Project 7",
      description: "ร้านค้า",
      imgUrl: projImg8,
    },
    {
      title: "Project 8",
      description: "เครื่องคิดเลข",
      imgUrl: projImg9,
    },
    {
      title: "Project 9",
      description: "ค้นหาประเทศ",
      imgUrl: projImg10,
    },
    {
      title: "Project 10",
      description: "Dark & Light",
      imgUrl: projImg11,
    },
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Projects</h2>
                <p>สิ่งที่เรียนมาทั้งหมด 17 สัปดาห์ ของรายวิชา 	30901-2108 การพัฒนาเว็บแอปพลิเคชันบนคอมพิวเตอร์พกพา</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Projects</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">เกี่ยวกับฉัน</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">ครูผู้สอน</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <p>นาย อรัญ โต๊ะสู<br/>แผนกวิชาเทคโนโลยีสารสนเทศ<br/>ชั้น ปวส 2 ปีการศึกษา 2566</p>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <p>ครู ศศิพิมพ์ กองสุข<br/>แผนกวิชาเทคโนโลยีสารสนเทศ <br/> วิทยาลัยเทคนิคนครศรีธรรมราช</p>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
